import React, { useState } from "react";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import { Contactform } from "./components/Contactform";
import { Numbers } from "./components/Numbers";
import Slider from "react-slick";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import img01 from "./images/banner01.webp";
import img02 from "./images/banner02.webp";
import img03 from "./images/banner03.webp";
import img04 from "./images/banner04.webp";
import img05 from "./images/Router.webp";
import img06 from "./images/home_banner_05.webp";
import img14 from "./images/banner_ott_playy.webp";
import img15 from "./images/banner 1_2.webp";
import img07 from "./images/slider-banner-6-1.png";
import img08 from "./images/jeeveshu.webp";
import img10 from "./images/ott_banner.webp";
import img11 from "./images/jeeveshu2.webp";
import img12 from "./images/ott_Bann_01.webp"
import img13 from "./images/banner11.webp"
import img16 from './images/smart_tv2.webp'
import img17 from './images/Iptv.webp'
import img18 from './images/FW+.webp'
import Service01 from "./images/broadband.webp";
import Service02 from "./images/voice.webp";
import Service03 from "./images/enterprise.webp";
import Service04 from "./images/android-tv-box.webp";
import Service05 from "./images/cloud-service.png";
import App from "./images/Reacharge.webp";
import Feature01 from "./images/Feature_img_01.webp";
import Feature02 from "./images/Feature_img_02.webp";
import Feature03 from "./images/Feature_img_3.webp";
import Feature04 from "./images/Feature_img_4.webp";
import Tech01 from "./images/cisco.webp";
import Tech02 from "./images/dell.webp";
import Tech03 from "./images/genexis.webp";
import Tech04 from "./images/tech04.webp";
import Tech05 from "./images/netapp.webp";
import Tech06 from "./images/tech06.webp";
import Tech07 from "./images/nokia.webp";
import Tech08 from "./images/zte.webp";
import Tech09 from "./images/covelogo.png";
import Speed from "./images/2xspeed.webp";
import { Broadband } from "./components/Broadband";
import { Entertainment } from "./components/Entertainment";
import { Link } from "react-router-dom";
import Popupimg from "./images/popupbanner.webp";
import Mimg05 from "./images/Mimg05.webp";
import { Jujharcont } from "./components/Jujharcont";
import { Helmet } from "react-helmet";
export const Home = () => {
  const [popup, setActive] = useState("false");
  const handleToggle = () => {
    setActive(!popup);
  };
  const [field, setfield] = useState("");
  function handleRadioChange(event) {
    setfield(event.target.value);
  }
  function handleButtonClick() {
    const clickedVal = field;
    if (clickedVal === "") {
      alert("Please select any plan");
      return false;
    } else if (clickedVal === "retail") {
      window.location.href = "retail/#broadband";
    } else {
      window.location.href = "enterprise";
    }
  }
  var settings = {
    dots: true,
    navigation: true,
    loop: true,
    fade: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    speed: 300,
    autoplaySpeed: 2000,
    arrows: false,
    cssEase: "linear",
  };
  var settings2 = {
    dots: false,
    navigation: true,
    loop: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    speed: 300,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1340,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <>
      <Helmet>
        <title>
          Netplus Broadband - Best Broadband Connection in Punjab,Chandigarh
        </title>
        <meta
          name="description"
          content="Best Broadband Connection in Punjab,Chandigarh,Haryana,Jammu& Kashmir, Utrakhand, Uttar Pradesh,Rajasthan"
        />
        <meta
          name="keywords"
          content="Broadband, Wi-fi, IPTV, Enterprise, Voice,know,unlimited,pay,truly,broadband,enterprise,netplus,mbps,Internet leased Lines,
 Enterprise business solutions,ILL,Internet Leased Line,Leased Line,DIA,LL"
        />
        <meta
          name="facebook-domain-verification"
          content="antd8gxm297s5u700ak31yasf43sag"
        />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <meta name="author" content="Call Planets Apps Solutions LLP" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="netplusofficial" />
        <meta
          name="twitter:title"
          content="Netplus Broadband - Best Broadband Connection in Punjab,Chandigarh"
        />
        <meta
          name="twitter:description"
          content="Netplus Broadband - Best Broadband Connection in Punjab,Chandigarh,Haryana,Jammu& Kashmir, Utrakhand, Uttar Pradesh,Rajasthan"
        />
        <meta name="linkage" content="https://www.netplus.co.in/" />
        <meta name="dc.source" content="https://www.netplus.co.in/" />
        <meta name="dc.relation" content="https://www.netplus.co.in/" />
        <link rel="canonical" href="https://www.netplus.co.in/" />
        <meta name="HandheldFriendly" content="True" />
      </Helmet>
      {/* Popup Start*/}
      {/* <div className={popup ? "popup" : "popup active"}>
        <div className="popimg">
          <button onClick={handleToggle} className="popdesign">
            X
          </button>
          <img src={Popupimg} alt="" />
        </div>
      </div> */}
      {/* Popup End */}



      <Header />



      <section
        id="banner"
        className="p-0 hbanner"
        data-aos="fade-left"
        data-aos-offset="200"
        data-aos-duration="600"
        data-aos-once="true"
        data-aos-easing="ease-in-sine"
      >
        <div className="banner h-auto ">
          <div className="container">
            <div className="row">
              <Slider {...settings}>


                {/* 1st banner */}

                <div>
                  <div className="sliderrow">
                    <div className="slideimg">
                      <img
                        src={img17}
                        alt="Banner Netplus"
                        className="M_algin"
                      />
                    </div>
                    <div className="slidecont">
                      <div className="circle01">
                        <div className="mngcircle">
                          <div className="circlimg">

                            <br />
                            <h2>Your gateway to Unmatched Entertainment!</h2><br />

                          </div>
                          <div className="listpoint">
                            <ul>
                              <li>450+ Live TV Channels</li>
                              <li>50 Mbps Unlimited Data</li>
                              <li>Premium OTT Apps</li>
                              <li>
                                Plans Starting At Just 598/-{" "}
                                {/* <strong>
                                  <i className="finr"></i>598/-
                                </strong> */}
                              </li>
                            </ul>
                          </div>
                          <div className="booknow">
                            <Link to="/book-now/bVhQYTVDZ1hCN3JkWnppc0g2N2lidz09">
                              Book Now{" "}
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="circle02">
                        <img
                          src={img18}
                          alt="FW+ logo"
                          className="M_algin"
                        />
                      </div>
                    </div>
                  </div>
                </div>


                {/* 2nd banner */}

                <div>
                  <div className="sliderrow">
                    <div className="slideimg">
                      <img
                        src={img16}
                        alt="Banner Netplus"
                        className="M_algin"
                      />
                    </div>
                    <div className="slidecont">
                      <div className="circle01">
                        <div className="mngcircle">
                          <div className="circlimg">
                            <h2>
                              Seamless streaming, Unrivaled quality!
                            </h2><br />
                          </div>
                          <div className="listpoint">
                            <ul>
                              <li>450+ Live HD TV</li>
                              <li>50 Mbps Unlimited Data</li>
                              <li>Premium OTT Apps</li>
                              <li>
                                Starting At Just 598/-{" "}
                                {/* <strong>
                                  <i className="finr"></i>598/-
                                </strong> */}
                              </li>
                            </ul>
                          </div>
                          <div className="booknow">
                            <Link to="/book-now/bVhQYTVDZ1hCN3JkWnppc0g2N2lidz09">
                              Book Now{" "}
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="circle02"><img
                        src={img18}
                        alt="FW+ logo"
                        className="M_algin"
                      /></div>
                    </div>
                  </div>
                </div>

                {/* 3rd banner */}

                <div>
                  <div className="sliderrow ">
                    <div className="slideimg">
                      <img
                        src={img14}
                        alt="Banner Netplus "
                        className="m_left_align"
                      />
                    </div>    &nbsp;&nbsp;
                    <div className="slidecont">
                      <div className="circle01">
                        <div className="mngcircle">
                          <div className="circlimg">

                            <h2>
                              <br /><strong> Truly Unlimited{" "}</strong> <br /><br />
                              Unlimited Internet<br />+ 25 OTTs


                              <div className="booknow">
                                <Link to="/book-now/T3A1bXA5WU9iT2poWkppZWpPWmZYUT09">
                                  Book Now{" "}
                                </Link>
                              </div>
                            </h2>

                          </div>
                        </div>
                      </div>
                      <div className="circle02">
                        <h2>
                          Plans <br /> starting <br />
                          &#8377;599/-
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>

                {/* 4th banner */}
                <div>
                  <div className="sliderrow">
                    <div className="slideimg">

                      <img src={img15} alt="Banner Netplus" />
                    </div>
                    <div className="slidecont">
                      <div className="circle01">
                        <div className="mngcircle">
                          <div className="circlimg">
                            <img src={Speed} alt="" />

                          </div>
                          <div className="pricestn">
                            <ul>
                              <li>
                                <strong>50</strong>
                              </li>
                              <li>100</li>
                              <li>Mbps</li>
                            </ul>
                            <ul>
                              <li>
                                <strong>100</strong>
                              </li>
                              <li>200</li>
                              <li>Mbps</li>
                            </ul>
                            <ul>
                              <li>
                                <strong>200</strong>
                              </li>
                              <li>400</li>
                              <li>Mbps</li>
                            </ul>
                            <h3>
                              Plans starting at just{" "}
                              <strong>
                                <span className="finr"></span>499
                              </strong>
                            </h3>
                            <div className="booknow">
                              <Link to="/book-now/OXRiOStrTUVDMDFXMW9QWnBnQWhJUT09">
                                Book Now{" "}
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="circle02">
                          <h2>
                            live <br /> double <br />
                            with
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                {/* 5th banner */}

                <div>
                  <div className="sliderrow">
                    <div className="slideimg">
                      <img
                        src={img11}
                        alt="Banner Netplus"
                        className="m_left_align"
                      />
                    </div>
                    <div className="slidecont">
                      <div className="circle01">
                        <div className="mngcircle">
                          <div className="circlimg">
                            <h2>
                              TRULY UNLIMITED{" "}
                              &nbsp;<div className="listpoint">
                                <ul>

                                  <li><>Unlimited Data</></li>
                                  <li><>Free OTT Apps</></li>
                                  <li>
                                    <>450+ Live TV Channels</>

                                  </li>
                                </ul>
                              </div>
                            </h2>
                          </div>
                        </div>
                      </div>
                      <div className="circle02">
                        <img
                          src={img10}
                          alt="Banner Netplus"
                          className="m_left_align"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* 6th banner */}
                <div>
                  <div className="sliderrow">
                    <div className="slideimg">
                      <img src={img04} alt="Banner Netplus" />
                    </div>
                    <div className="slidecont">
                      <div className="circle01">
                        <div className="mngcircle">
                          <div className="packmodule">
                            <ul className="freepacks">
                              <li>
                                <span>PAY FOR</span>
                                <strong>
                                  5 <small>MONTHS </small>
                                </strong>
                              </li>
                              <li>
                                <span> AND GET</span>
                                <strong>
                                  1 <small>MONTHS Free</small>
                                </strong>
                              </li>
                            </ul>
                            <ul className="freepacks">
                              <li>
                                <span>PAY FOR</span>
                                <strong>
                                  10 <small>MONTHS </small>
                                </strong>
                              </li>
                              <li>
                                <span> AND GET</span>
                                <strong>
                                  2 <small>MONTHS Free</small>
                                </strong>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="circle02">
                        <h2>
                          Extra
                          <br />
                          Saving
                          <br />
                          Bundle
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>

                {/* 5th banner */}
                {/* <div>
                  <div className="sliderrow ">
                    <div className="slideimg">
                      <img
                        src={img07}
                        alt="Banner Netplus "
                        className="m_left_align"
                      />
                    </div>
                    <div className="slidecont">
                      <div className="circle01">
                        <div className="mngcircle">
                          <div className="circlimg">
                            <h2>
                              Store your files and documents in a secure cloud.{" "}
                              <strong>Starting @ ₹49/month</strong>
                            </h2>
                          </div>
                        </div>
                      </div>
                      <div className="circle02"></div>
                    </div>
                  </div>
                </div> */}

                {/* 6th banner */}
                {/* <div>
                  <div className="sliderrow ">
                    <div className="slideimg">
                      <img
                        src={img04}
                        alt="Banner Netplus "
                        className="m_left_align"
                      />
                    </div>
                    <div className="slidecont">
                      <div className="circle01">
                        <div className="mngcircle">
                          <div className="circlimg">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <h2>
                              Get Free 16 OTT APPS with NO Extra cost{" "}<br />
                              <div className="listpoint">

                                <ul>
                                  <li>Unlimited Data</li>
                                  <li>Unlimited Calls</li>
                                  <li>350+ Live TV Channels </li>
                                </ul>
                              </div>
                            </h2>

                          </div>
                        </div>
                      </div>
                      <div className="circle02">
                        <h2>
                          Plans <br /> starting <br />
                          &#8377;598/-
                        </h2>
                      </div>
                    </div>
                  </div>
                </div> */}

                {/* 7th Banner */}
                <div>
                  <div className="sliderrow">
                    <div className="slideimg">
                      <img
                        src={img03}
                        alt="Banner Netplus"
                        className="m_left_align"
                      />
                    </div>
                    <div className="slidecont">
                      <div className="circle01">
                        <div className="mngcircle">
                          <div className="circlimg">
                            <h2>
                              Customize Your Own Plans With our OTT{" "}
                              <strong>SUPER SAVER PACK</strong>
                            </h2>
                          </div>
                        </div>
                      </div>
                      <div className="circle02"></div>
                    </div>
                  </div>
                </div>


                {/* LAST */}
                <div>
                  <div className="sliderrow">
                    <div className="slideimg">
                      <img
                        src={img02}
                        alt="Banner Netplus"
                        className="M_algin"
                      />
                    </div>
                    <div className="slidecont">
                      <div className="circle01">
                        <div className="mngcircle">
                          <div className="circlimg">
                            <h2>
                              Get Unlimited <span>Streaming with</span> Next-gen
                              4K Android TV Box
                            </h2> <br />
                          </div>
                          <div className="listpoint">
                            <ul>
                              <li>450+ Live HD TV</li>
                              <li>100 Mbps</li>
                              <li>Unlimited Data</li>
                              <li>
                                Starting At Just 699/-{" "}
                                {/* <strong>
                                  <i className="finr"></i>699/m
                                </strong> */}
                              </li>
                            </ul>
                          </div>
                          <div className="booknow">
                            <Link to="/book-now/bVhQYTVDZ1hCN3JkWnppc0g2N2lidz09">
                              Book Now{" "}
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="circle02"></div>
                    </div>
                  </div>
                </div>


              </Slider>
            </div>
          </div>
        </div>
      </section>




      <section id="chooseplan">
        <div className="container">
          <div className="row">
            <div
              className="chooseplan bgcolor1"
              data-aos="fade-up"
              data-aos-offset="100"
              data-aos-duration="600"
              data-aos-once="true"
              data-aos-easing="ease-in-sine"
            >
              <div className="web-container">
                <div className="title">
                  <h3>Your net. Your plan.</h3>
                </div>
                <div className="containers">
                  <input
                    type="radio"
                    name="field"
                    id="Uno"
                    value="retail"
                    checked={field === "retail"}
                    onChange={handleRadioChange}
                  />
                  <label htmlFor="Uno">
                    <svg className="check" viewBox="0 0 60 60">
                      <defs>
                        <linearGradient
                          id="gradient"
                          x1="0"
                          y1="0"
                          x2="0"
                          y2="100%"
                        >
                          <stop offset="0%" stopColor="#ff8a00"></stop>
                          <stop offset="100%" stopColor="#da1b60"></stop>
                        </linearGradient>
                      </defs>
                      <circle id="border" r="18px" cx="20px" cy="20px"></circle>
                      <circle id="dot" r="8px" cx="20px" cy="20px"></circle>
                    </svg>
                    Retail
                  </label>
                  <input
                    type="radio"
                    name="field"
                    id="Dos"
                    value="enterprise"
                    checked={field === "enterprise"}
                    onChange={handleRadioChange}
                  />
                  <label htmlFor="Dos">
                    <svg className="check" viewBox="0 0 60 60">
                      <defs>
                        <linearGradient
                          id="gradient"
                          x1="0"
                          y1="0"
                          x2="0"
                          y2="100%"
                        >
                          <stop offset="0%" stopColor="#ff8a00"></stop>
                          <stop offset="100%" stopColor="#da1b60"></stop>
                        </linearGradient>
                      </defs>
                      <circle id="border" r="18px" cx="20px" cy="20px"></circle>
                      <circle id="dot" r="8px" cx="20px" cy="20px"></circle>
                    </svg>
                    Enterprise
                  </label>
                </div>
                <div className="butn">
                  <div className="buttnprop" onClick={handleButtonClick}>
                    <span>Explore Plans</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="service">
        <div className="service">
          <div className="container">
            <div className="row">
              <div
                className="title"
                data-aos="fade-up"
                data-aos-offset="100"
                data-aos-duration="600"
                data-aos-once="true"
                data-aos-easing="ease-in-sine"
              >
                <span>Our Services</span>
                <h3>Get connected. Glitch free.</h3>
              </div>
              <div
                className="netpluslide"
                data-aos="fade-up"
                data-aos-offset="200"
                data-aos-duration="600"
                data-aos-once="true"
                data-aos-easing="ease-in-sine"
              >
                <Slider {...settings2}>
                  <div>
                    <div className="servicebox">
                      <div className="serviceimg">
                        <img src={Service01} alt="" />
                      </div>
                      <div className="servicecont">
                        <h4>BROADBAND</h4>
                        <p>
                          Unlimited internet at the speed of up to 1000 Mbps
                        </p>
                        <div className="butn">
                          <div className="buttnprop">
                            <Link to="/retail">
                              <span>Know More</span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="servicebox">
                      <div className="serviceimg">
                        <img src={Service05} alt="" />
                      </div>
                      <div className="servicecont">
                        <h4>SECURE CLOUD STORAGE</h4>
                        <p>Store your files and documents in a secure cloud.</p>
                        <div className="butn">
                          <div className="buttnprop">
                            <Link to="/cloud-storage">
                              <span>Know More</span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="servicebox">
                      <div className="serviceimg">
                        <img src={Service02} alt="" />
                      </div>
                      <div className="servicecont">
                        <h4>VOICE</h4>
                        <p>
                          Enhance your voice experience on STD and local calls
                          with Netplus smart telephony
                        </p>
                        <div className="butn">
                          <div className="buttnprop">
                            <Link to="/voice">
                              <span>Know More</span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="servicebox">
                      <div className="serviceimg">
                        <img src={Service03} alt="" />
                      </div>
                      <div className="servicecont">
                        <h4>ENTERPRISE</h4>
                        <p>
                          Enterprise business solutions with internet leased
                          line
                        </p>
                        <div className="butn">
                          <div className="buttnprop">
                            <Link to="/enterprise">
                              <span>Know More</span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="servicebox">
                      <div className="serviceimg">
                        <img src={Service04} alt="" />
                      </div>
                      <div className="servicecont">
                        <h4>IPTV BOX</h4>
                        <p>
                          Revolutionize your viewing with FW+
                        </p>
                        <div className="butn">
                          <div className="buttnprop">
                            <Link to="/ip-tv">
                              <span>Know More</span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="servicebox">
                      <div className="serviceimg">
                        <img src={Service01} alt="" />
                      </div>
                      <div className="servicecont">
                        <h4>NETPLUS HD BUNDLE</h4>
                        <p>
                          Netplus Live HD Streaming with unlimited data/calls
                          and OTT subscription under one plan
                        </p>
                        <div className="butn">
                          <div className="buttnprop">
                            <Link to="/netplus-hd-bundle">
                              <span>Know More</span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="plans">
        <div
          className="plans"
          data-aos="fade-up"
          data-aos-offset="200"
          data-aos-duration="600"
          data-aos-once="true"
          data-aos-easing="ease-in-sine"
        >
          <div className="container">
            <div className="row">
              <div className="planstab">
                <Tabs>
                  <TabList>
                    <Tab>BROADBAND PLANS</Tab>
                    <Tab>ENTERTAIMENT PLANS</Tab>
                  </TabList>
                  <TabPanel>
                    <div className="planstabpanel">
                      <div className="title">
                        <h3>Your need. Your choice.</h3>
                      </div>
                      <Broadband />
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="planstabpanel">
                      <div className="title">
                        <h3>Your need. Your choice.</h3>
                      </div>
                      <Entertainment />
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="app">
        <div className="app">
          <div className="container">
            <div className="row">
              <div className="gradientapp">
                <div
                  className="appleft"
                  data-aos="fade-left"
                  data-aos-offset="200"
                  data-aos-duration="600"
                  data-aos-once="true"
                  data-aos-easing="ease-in-sine"
                >
                  <div className="appbox">
                    <h3>
                      Pay. Recharge. <br /> In just one click.
                    </h3>
                    <div className="appcta">
                      <span>
                        <Link to="/pay-bill">Pay Bills / Recharge</Link>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="apprgt ">
                  <div
                    className="appimg"
                    data-aos="fade-up"
                    data-aos-offset="200"
                    data-aos-duration="600"
                    data-aos-once="true"
                    data-aos-easing="ease-in-sine"
                  >
                    <img src={App} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="feature">
        <div className="feature">
          <div className="container">
            <div className="row">
              <div className="web-container">
                <div
                  className="title"
                  data-aos="fade-up"
                  data-aos-offset="200"
                  data-aos-duration="600"
                  data-aos-once="true"
                  data-aos-easing="ease-in-sine"
                >
                  <span>Features</span>
                  <h3>Stay ahead. With double the speed.</h3>
                </div>
                <div className="featurestn">
                  <div className="featurerow">
                    <div
                      className="feartureimg"
                      data-aos="fade-left"
                      data-aos-offset="200"
                      data-aos-duration="600"
                      data-aos-once="true"
                      data-aos-easing="ease-in-sine"
                    >
                      <img src={Feature01} alt="" />
                    </div>
                    <div
                      className="featurecont"
                      data-aos="fade-right"
                      data-aos-offset="200"
                      data-aos-duration="600"
                      data-aos-once="true"
                      data-aos-easing="ease-in-sine"
                    >
                      <div className="featurebox">
                        <span>1</span>
                        <div className="featureInfo">
                          <h4>High speed Internet</h4>
                          <p>Explore at lightning fast speed</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="featurerow">
                    <div
                      className="feartureimg"
                      data-aos="fade-left"
                      data-aos-offset="200"
                      data-aos-duration="600"
                      data-aos-once="true"
                      data-aos-easing="ease-in-sine"
                    >
                      <img src={Feature02} alt="" />
                    </div>
                    <div
                      className="featurecont"
                      data-aos="fade-right"
                      data-aos-offset="200"
                      data-aos-duration="600"
                      data-aos-once="true"
                      data-aos-easing="ease-in-sine"
                    >
                      <div className="featurebox">
                        <span>2</span>
                        <div className="featureInfo">
                          <h4>Reliability</h4>
                          <p>Trusted by majority of users in Punjab.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="featurerow">
                    <div
                      className="feartureimg"
                      data-aos="fade-left"
                      data-aos-offset="200"
                      data-aos-duration="600"
                      data-aos-once="true"
                      data-aos-easing="ease-in-sine"
                    >
                      <img src={Feature03} alt="" />
                    </div>
                    <div
                      className="featurecont"
                      data-aos="fade-right"
                      data-aos-offset="200"
                      data-aos-duration="600"
                      data-aos-once="true"
                      data-aos-easing="ease-in-sine"
                    >
                      <div className="featurebox">
                        <span>3</span>
                        <div className="featureInfo">
                          <h4>Entertainment packages</h4>
                          <p>
                            Get exciting combo deals for TV, Calling and
                            broadband.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="featurerow">
                    <div
                      className="feartureimg"
                      data-aos="fade-left"
                      data-aos-offset="200"
                      data-aos-duration="600"
                      data-aos-once="true"
                      data-aos-easing="ease-in-sine"
                    >
                      <img src={Feature04} alt="" />
                    </div>
                    <div
                      className="featurecont"
                      data-aos="fade-right"
                      data-aos-offset="200"
                      data-aos-duration="600"
                      data-aos-once="true"
                      data-aos-easing="ease-in-sine"
                    >
                      <div className="featurebox">
                        <span>4</span>
                        <div className="featureInfo">
                          <h4>Affordable pricing</h4>
                          <p>Entertainment is 2X when you pay less.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="number" className="pt-0">
        <div className="number">
          <div className="container">
            <div className="row">
              <div className="web-container">
                <div
                  className="title"
                  data-aos="fade-up"
                  data-aos-offset="200"
                  data-aos-duration="600"
                  data-aos-once="true"
                  data-aos-easing="ease-in-sine"
                >
                  <h3>Your No.1 broadband</h3>
                </div>
                <Numbers />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="technology">
        <div className="technologystn">
          <div className="container">
            <div className="row">
              <div className="web-container">
                <div
                  className="title"
                  data-aos="fade-up"
                  data-aos-offset="200"
                  data-aos-duration="600"
                  data-aos-once="true"
                  data-aos-easing="ease-in-sine"
                >
                  <h3>Our Technological Marvels.</h3>
                </div>
                <div className="technology">
                  <ul
                    data-aos="fade-up"
                    data-aos-offset="200"
                    data-aos-duration="600"
                    data-aos-once="true"
                    data-aos-easing="ease-in-sine"
                  >
                    <li>
                      <img src={Tech01} alt="" />
                    </li>
                    <li>
                      <img src={Tech02} alt="" />
                    </li>
                    <li>
                      <img src={Tech03} alt="" />
                    </li>
                    <li>
                      <img src={Tech04} alt="" />
                    </li>
                    <li>
                      <img src={Tech05} alt="" />
                    </li>
                    <li>
                      <img src={Tech06} alt="" />
                    </li>
                    <li>
                      <img src={Tech07} alt="" />
                    </li>
                    {/* <li><img src={Tech08} alt='' /></li> */}
                    <li>
                      <div>
                        <img style={{ maxWidth: "100%" }} src={Tech09} alt="" />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Jujharcont />
      {/* <Contactform /> */}
      <Footer />
    </>
  );
};
export default Home;
